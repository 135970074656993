<template>
	<div
		:id="id"
		class="w-full"
	>
		<slot
			name="actionBanner"
			:selected-rows="selectedRows"
			:batch-action-id="batchAction.id"
		>
			<!-- Selected Items Banner -->
			<Transition
				enter-active-class="transition-all duration-100 ease-in-out"
				enter-from-class="translate-y-[-100%]"
				enter-to-class="translate-y-0"
				leave-active-class="transition-all duration-100 ease-in-out"
				leave-from-class="translate-y-0"
				leave-to-class="translate-y-[-100%]"
			>
				<div
					v-if="selectedRows.length > 0 && batchAction.id !== ''"
					class="flex items-center justify-between p-4 mb-4 rounded-lg shadow-sm bg-mx-gray-200 dark:bg-mx-green-800 dark:text-mx-gray-300"
				>
					<div>
						<strong class="font-semibold">
							{{ selectedRows.length }}
							<!-- TODO i18n -->
						</strong> items selected
						<strong class="font-semibold text-mx-orange">
							<span class="font-normal text-black dark:text-mx-gray-300">
								<!-- TODO i18n -->
								for <span class="font-semibold">
									Batch Action:
								</span>
							</span>
							{{ batchAction.label }}
						</strong>
					</div>
					<div class="flex items-center gap-4">
						<FormButton
							variant="tertiary-foreground"
							button-width="w-auto"
							class="whitespace-nowrap"
							@click="clearSelectedRows"
						>
							<!-- TODO i18n -->
							Clear Selections
						</FormButton>
						<FormButton
							button-width="w-auto"
							class="whitespace-nowrap"
							@click="emitStartBatchAction"
						>
							<!-- TODO i18n -->
							Start Batch Action Workflow
						</FormButton>
					</div>
				</div>
			</Transition>
		</slot>
		<div class="flex w-full mx-auto justify-items-center">
			<slot name="header">
				<div
					v-if="!hideHeader"
					class="pb-4 text-2xl font-semibold dark:text-mx-gray-50"
				>
					{{ title }}
				</div>
				<div
					v-if="showCreateButton"
					class="ml-auto"
				>
					<button
						class="primary-btn"
						@click="emitOpenCreateModal"
					>
						<span class="mt-0.5">
							<!-- TODO i18n -->
							Create {{ itemName }}
						</span>
					</button>
				</div>
			</slot>
		</div>
		<slot name="outermostTopRightControls">
			<div
				:class="[
					'flex items-bottom flex-wrap gap-2 w-full',
					{ 'justify-end' : showTableControlBar }
				]"
			>
				<slot name="extraControlsOutermostTopRight" />
			</div>
		</slot>
		<div class="w-full">
			<div class="flex flex-wrap justify-between w-full">
				<slot name="presetFilters">
					<div
						v-if="presetFilters.length"
						class="flex flex-wrap py-2 mt-auto"
					>
						<div class="grid grid-cols-2 gap-2 py-2 md:flex md:flex-wrap">
							<NuxtLink
								v-if="id === 'accountsTable'"
								class="flex justify-center h-10 px-4 py-2 text-xs font-bold transition-all duration-100 ease-in-out bg-white rounded-lg shadow whitespace-nowrap text-mx-gray-500 dark:text-mx-gray-300 dark:bg-mx-green-700 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600"
								:to="`${PORTAL_BASE}/accounts?filters=accountStatusPendingInstall&filters=outsideInstalledYes&filters=siteSurveyedYes&filters=cablePulledYes&filters=cableTerminatedYes&filters=cableSplicedYes&filters=insideInstalledYes&page=1&perPage=50&searchQuery=&sortColumn=id&sortOrder=DESC&columns=id&columns=name&columns=phone&columns=address&columns=neighborhood&columns=accountStatus&columns=email`"
							>
								<span class="m-auto">
									<!-- TODO i18n -->
									Needs Activation
								</span>
							</NuxtLink>
							<div
								v-for="filter in presetFiltersWithAllOption"
								:key="filter.id"
							>
								<button
									v-if="filter.filters?.length"
									:class="[
										showPresetActive(filter.filters) ? 'bg-mx-orange text-white hover:bg-mx-orange-muted' : 'text-mx-gray-500 bg-white dark:bg-mx-green-700 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600',
										'w-full md:w-auto h-10 px-4 py-2 text-xs font-bold transition-all whitespace-nowrap duration-100 ease-in-out rounded-lg shadow text-mx-gray-500 dark:text-mx-gray-300'
									]"
									@click="addOrRemovePreset(filter)"
								>
									{{ filter.label }}
								</button>
								<div
									v-if="filter.options?.length"
									class="relative flex flex-wrap w-full gap-2 md:w-auto"
								>
									<Combobox multiple>
										<div class="flex w-full gap-2 flex-nowrap md:w-auto">
											<ComboboxButton
												:class="controlButtonClasses"
												class="bg-white whitespace-nowrap w-full md:w-auto justify-center md:justify-normal inline-flex gap-x-1.5 items-center shadow-sm dark:bg-mx-green-700 px-4 py-1.5 rounded-lg text-xs font-bold text-mx-gray-500 dark:text-mx-gray-300 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600"
											>
												<span
													class="after:content-[':']"
													:class="{'after:text-transparent' : !presetActiveLabel(filter.label) }"
												>
													{{ presetLabel(filter.label) }}
												</span>
												<span
													v-if="presetActiveLabel(filter.label)"
													class="text-mx-orange"
												>
													{{ presetActiveLabel(filter.label) }}
												</span> <ChevronDownIcon
													class="w-5 h-5 -mr-1.5 text-mx-gray-400"
													aria-hidden="true"
												/>
											</ComboboxButton>
											<ComboboxOptions class="absolute left-0 top-[52px] z-20 w-full min-w-72 mt-1 overflow-auto bg-white dark:bg-mx-green-800 rounded-md shadow-lg max-h-[500px] cursor-pointer">
												<div
													v-for="option in filter.options"
													:key="option.id"
													class="relative w-full"
												>
													<ComboboxOption
														v-slot="{ active }"
														:value="option"
													>
														<!-- TODO i18n -->
														<FormCheckbox
															:id="option.id"
															:class="[
																{ 'bg-mx-gray-200 dark:bg-mx-green-600 dark:text-white': active },
																{ 'text-black dark:text-mx-gray-300': !active },
																'relative py-2 pl-4 pr-10 cursor-pointer select-none whitespace-nowrap w-full'
															]"
															:checked="showPresetOptionSelected(option.id, filter.options)"
															:label-text="option.label"
															label-classes="after:text-lg after:font-semibold cursor-pointer"
															wrapper-classes="w-full flex items-center"
															checkbox-border-color="border-mx-gray-300 dark:border-mx-gray-500"
															size="md"
															@change="addOrRemovePresetOptions(option.id?.toString(), filter)"
														/>
													</ComboboxOption>
													<div v-if="option?.children?.length">
														<Disclosure
															v-slot="{ open }"
															as="div"
														>
															<DisclosureButton class="absolute top-0 right-0 px-4 py-2.5 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600">
																<ChevronDownIcon
																	class="w-5 h-5 transition-transform text-mx-gray-400"
																	:class="{ 'transform rotate-180': open }"
																	aria-hidden="true"
																/>
															</DisclosureButton>
															<Transition name="fade-and-slide">
																<DisclosurePanel
																	as="div"
																	class="py-0"
																>
																	<ComboboxOption
																		v-for="child in option.children"
																		:key="child.id"
																		v-slot="{ active: childActive }"
																		:value="child"
																		class="relative flex justify-start w-full h-full pl-6"
																	>
																		<FormCheckbox
																			:id="child.id"
																			:class="[
																				{ 'bg-mx-gray-200 dark:bg-mx-green-600 dark:text-white': childActive },
																				{ 'text-black dark:text-mx-gray-300': !childActive },
																				'relative py-2 pl-4 pr-10 cursor-pointer select-none whitespace-nowrap w-full'
																			]"
																			:checked="showPresetOptionSelected(child.id, filter.options)"
																			:label-text="child?.label"
																			label-classes="after:text-lg after:font-semibold cursor-pointer"
																			wrapper-classes="w-full flex items-center"
																			checkbox-border-color="border-mx-gray-300 dark:border-mx-gray-500"
																			size="md"
																			@change="addOrRemovePresetOptions(child.id?.toString(), filter)"
																		/>
																	</ComboboxOption>
																</DisclosurePanel>
															</Transition>
														</Disclosure>
													</div>
												</div>
											</ComboboxOptions>
										</div>
									</Combobox>
								</div>
							</div>
						</div>
					</div>
				</slot>

				<slot name="outerTopRightControls">
					<div
						:class="[
							'flex items-bottom flex-wrap gap-2 ml-auto',
							{ 'justify-end' : showTableControlBar }
						]"
					>
						<slot name="extraControlsTopRight" />

						<slot name="cellSizeSelect">
							<div
								v-if="allowTableExport || cellSizeSelect || tableControls"
								:class="[
									'flex mt-auto gap-2 pb-4 pl-2 ml-auto',
								]"
							>
								<slot name="table-export">
									<button
										v-if="(allowTableExport || tableControls) && !showEmptyState && !isLoading"
										:class="controlButtonClasses"
										class="flex items-center px-3 ml-auto text-xs font-bold transition-all duration-100 ease-in-out bg-white rounded-lg shadow text-mx-gray-500 dark:bg-mx-green-700 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 dark:text-mx-gray-300"
										@click="copyTablePageToClipboard"
									>
										<span class="mr-1.5">
											<!-- TODO i18n -->
											Export
										</span><SolidTableCellsIcon class="h-5" />
									</button>
								</slot>

								<!-- Table Cell Size Select Buttons -->
								<slot name="cell-size">
									<Combobox
										v-if="cellSizeSelect || tableControls"
										v-model="userSelectedCellSize"
										as="div"
										class="relative"
									>
										<div class="flex items-center gap-2 flex-nowrap">
											<ComboboxButton
												:class="controlButtonClasses"
												class="bg-mx-gray-100 whitespace-nowrap inline-flex gap-x-1.5 items-center shadow-sm dark:bg-mx-green-700 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 px-4 py-2.5 rounded-lg text-sm font-bold text-mx-gray-500 dark:text-mx-gray-300"
											>
												<span
													:class="[
														'font-semibold select-none',
														{'text-sm': cellSize === 'sm'},
														{'text-lg': cellSize === 'md'},
														{'text-2xl': cellSize === 'lg'}
													]"
												>
													A
												</span>
												<ChevronDownIcon
													class="w-5 h-5 -mr-1.5 text-mx-gray-400"
													aria-hidden="true"
												/>
											</ComboboxButton>
											<ComboboxOptions class="top-[40px] absolute right-0 z-20 w-auto overflow-x-hidden mt-1 overflow-auto bg-white dark:bg-mx-green-800 rounded-md shadow-lg max-h-[500px]">
												<ComboboxOption
													v-for="option in cellSizeOptions"
													:key="option"
													v-slot="{ active, selected }"
													:value="option"
												>
													<div
														class="relative py-2 pl-4 pr-10 cursor-pointer select-none whitespace-nowrap"
														:class="[
															{ 'bg-mx-gray-100 dark:bg-mx-green-600 dark:text-white': active },
															{ 'text-black dark:text-mx-gray-300': !active }
														]"
													>
														<span
															:class="[
																'font-semibold select-none',
																{'text-sm': option === 'sm'},
																{'text-lg': option === 'md'},
																{'text-2xl': option === 'lg'}
															]"
														>
															A
														</span>
														<span
															v-if="selected"
															class="absolute inset-y-0 right-0 flex items-center pr-3"
														>
															✓
														</span>
													</div>
												</ComboboxOption>
											</ComboboxOptions>
										</div>
									</Combobox>
								</slot>
							</div>
						</slot>
					</div>
				</slot>
			</div>
			<div class="w-full bg-white rounded-lg shadow dark:bg-mx-green-700">
				<slot name="table-controls">
					<div
						:class="[
							showTableControlBar ? controlBarClasses : '',
							'flex justify-between w-full gap-2 justify-items-center flex-wrap'
						]"
					>
						<!-- Left Positioned Controls -->
						<div class="grid items-center w-full grid-cols-2 gap-2 md:flex columns-2 md:flex-wrap xl:flex-nowrap md:w-auto">
							<slot name="controlBarHeader" />
							<slot
								name="search"
								:search-query="searchQuery"
							>
								<div
									v-if="searchBar || tableControls"
									class="flex w-full col-span-2 md:w-auto md:col-auto"
								>
									<FormSearchDropdown
										v-if="searchableColumns.length > 0"
										:options="searchableColumns"
										:initial-value="searchQuery"
										:dropdown-classes="dropdownClasses"
										:class="controlButtonClasses"
										min-width="md:min-w-64 min-w-56 w-full md:w-auto"
										@search="search"
									/>
								</div>
							</slot>
							<slot name="filters">
								<Menu
									v-if="showFilters"
									as="div"
									class="relative inline-block w-full text-left group"
								>
									<div class="flex w-full">
										<MenuButton
											:class="controlButtonClasses"
											class="inline-flex justify-center w-full items-center gap-x-1.5 rounded-md bg-mx-gray-100 dark:bg-mx-green-800 px-4 py-2 whitespace-nowrap text-sm font-semibold text-mx-gray-500 dark:text-mx-gray-300 shadow-sm hover:bg-mx-gray-200 dark:hover:bg-mx-green-600"
										>
											{{ activeFilterLabel }}
											<ChevronDownIcon
												class="w-5 h-5 -mr-1.5 text-mx-gray-400"
												aria-hidden="true"
											/>
										</MenuButton>
									</div>

									<Transition
										enter-active-class="transition duration-100 ease-out"
										enter-from-class="transform scale-95 opacity-0"
										enter-to-class="transform scale-100 opacity-100"
										leave-active-class="transition duration-75 ease-in"
										leave-from-class="transform scale-100 opacity-100"
										leave-to-class="transform scale-95 opacity-0"
									>
										<MenuItems class="absolute right-0 z-20 w-auto mt-2 origin-top-right bg-white rounded-md shadow-lg dark:bg-mx-green-800 focus:outline-none">
											<div class="first-of-type:rounded-t-md last-of-type:rounded-b-md">
												<MenuItem
													v-for="(filter, index) in filters"
													:key="filter.id"
													as="div"
													class="first-of-type:rounded-t-md last-of-type:rounded-b-md"
												>
													<template #default="{ active }">
														<button
															:class="[
																active ? 'bg-mx-gray-200 dark:bg-mx-green-600 dark:text-white' : 'text-mx-gray-700 dark:text-mx-gray-300',
																'block w-full px-4 py-2 text-left focus:outline-transparent whitespace-nowrap',
																{ 'rounded-t-md' : index === 0 },
																{ 'rounded-b-md' : index === filters.length - 1 }
															]"
															@click="handleFilterChange(filter.id.toString())"
														>
															{{ filter.label }}
														</button>
													</template>
												</MenuItem>
											</div>
										</MenuItems>
									</Transition>
								</Menu>
								<button
									v-if="activeFilterKey"
									:class="controlButtonClasses"
									class="-ml-0.5 text-xs font-bold uppercase text-mx-orange hover:bg-mx-gray-200 dark:hover:bg-mx-green-800 px-2 py-1.5 rounded-lg cursor-pointer"
									@click="activeFilterKey = ''"
								>
									<!-- TODO i18n -->
									reset
								</button>
							</slot>
							<slot name="multiFilterSelect">
								<div
									v-if="multiFilterSelect"
									class="relative flex w-full gap-2 md:w-auto"
								>
									<Combobox multiple>
										<div class="flex w-full gap-2 flex-nowrap md:w-auto">
											<ComboboxButton
												:class="controlButtonClasses"
												class="bg-mx-gray-100 whitespace-nowrap w-full md:w-auto inline-flex gap-x-1.5 justify-center md:justify-normal items-center shadow-sm dark:bg-mx-green-800 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 px-4 py-1.5 rounded-lg text-sm font-bold text-mx-gray-500 dark:text-mx-gray-300"
											>
												{{ multiFilterLabel }} <ChevronDownIcon
													class="w-5 h-5 -mr-1.5 text-mx-gray-400"
													aria-hidden="true"
												/>
											</ComboboxButton>
											<ComboboxOptions
												:class="dropdownClasses"
												class="absolute left-0 z-20 w-auto overflow-x-hidden mt-1 overflow-auto bg-white dark:bg-mx-green-800 rounded-md shadow-lg max-h-[500px]"
											>
												<ComboboxOption
													v-for="filter in filters"
													:key="filter.id"
													v-slot="{ active }"
													:value="filter"
													@click="addOrRemoveFilter(filter.id.toString())"
												>
													<div
														class="relative py-2 pl-4 pr-10 cursor-pointer select-none whitespace-nowrap"
														:class="[
															{ 'bg-mx-gray-100 dark:bg-mx-green-600 dark:text-white': active },
															{ 'text-black dark:text-mx-gray-300': !active }
														]"
													>
														<span class="pr-3">
															<!-- TODO i18n -->
															{{ filter.label }}
														</span>
														<span
															v-if="activeMultiFilterIds.includes(filter.id)"
															class="absolute inset-y-0 right-0 flex items-center pr-3"
														>
															✓
														</span>
													</div>
												</ComboboxOption>
											</ComboboxOptions>
											<button
												v-if="showMultiFilterReset()"
												class="-ml-0.5 text-xs font-bold uppercase text-mx-orange hover:bg-mx-gray-200 dark:hover:bg-mx-green-800 px-2 py-1.5 rounded-lg cursor-pointer"
												@click="handleResetMultiFilters"
											>
												<!-- TODO i18n -->
												reset
											</button>
										</div>
									</Combobox>
								</div>
							</slot>
							<slot name="column-select">
								<div
									v-if="columnSelect || tableControls"
									class="relative flex w-full gap-2 md:w-auto"
								>
									<Combobox multiple>
										<div class="flex w-full gap-2 flex-nowrap md:w-auto">
											<ComboboxButton
												:class="controlButtonClasses"
												class="bg-mx-gray-100 whitespace-nowrap w-full md:w-auto justify-center md:justify-normal inline-flex gap-x-1.5 items-center shadow-sm dark:bg-mx-green-800 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 px-4 py-1.5 rounded-lg text-sm font-bold text-mx-gray-500 dark:text-mx-gray-300"
											>
												Columns <ChevronDownIcon
													class="w-5 h-5 -mr-1.5 text-mx-gray-400"
													aria-hidden="true"
												/>
											</ComboboxButton>
											<ComboboxOptions
												:class="dropdownClasses"
												class="absolute left-0 z-20 w-full min-w-60 mt-1 overflow-auto bg-white dark:bg-mx-green-800 rounded-md shadow-lg max-h-[500px]"
											>
												<ComboboxOption
													v-for="column in columns"
													:key="column.key"
													v-slot="{ active }"
													:value="column"
													@click="addOrRemoveColumn(column.key.toString())"
												>
													<div
														class="relative py-2 pl-4 pr-10 cursor-pointer select-none whitespace-nowrap"
														:class="[
															{ 'bg-mx-gray-100 dark:bg-mx-green-600 dark:text-white': active },
															{ 'text-black dark:text-mx-gray-300': !active }
														]"
													>
														<!-- TODO i18n -->
														{{ column.label }} <span
															v-if="selectedColumnKeys.includes(column.key?.toString())"
															class="absolute inset-y-0 right-0 flex items-center pr-3"
														>
															✓
														</span>
													</div>
												</ComboboxOption>
											</ComboboxOptions>
											<button
												v-if="showColumnReset"
												class="-ml-0.5 text-xs font-bold uppercase text-mx-orange hover:bg-mx-gray-200 dark:hover:bg-mx-green-800 px-2 py-1.5 rounded-lg cursor-pointer"
												@click="resetColumnSelect"
											>
												<!-- TODO i18n -->
												reset
											</button>
										</div>
									</Combobox>
								</div>
							</slot>

							<!-- Batch Action Select Options -->
							<slot
								v-if="batchSelect"
								name="batchActionSelectOptions"
								:selected-rows="selectedRows"
								:batch-action-id="batchAction.id"
							>
								<Combobox
									v-if="batchActionMenuOptions?.length"
									v-model="batchAction"
									as="div"
									class="relative w-full md:w-auto"
								>
									<div class="flex items-center w-full gap-2 flex-nowrap md:w-auto">
										<ComboboxButton
											:class="controlButtonClasses"
											class="bg-mx-gray-100 whitespace-nowrap w-full md:w-auto justify-center md:justify-normal inline-flex gap-x-1.5 items-center shadow-sm dark:bg-mx-green-800 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 px-4 py-2.5 rounded-lg text-sm font-bold text-mx-gray-500 dark:text-mx-gray-300"
										>
											<span :class="batchAction.id ? `after:content-[':'] after:-ml-0.5` : ''">
												<!-- TODO i18n -->
												Batch Action
											</span>
											<span
												v-if="batchAction.id"
												class="text-mx-orange"
											>
												<!-- TODO i18n -->
												{{ batchAction.label }}
											</span>
											<ChevronDownIcon
												class="w-5 h-5 -mr-1.5 text-mx-gray-400"
												aria-hidden="true"
											/>
										</ComboboxButton>
										<ComboboxOptions class="top-[40px] absolute left-0 z-20 w-auto overflow-x-hidden mt-1 overflow-auto bg-white dark:bg-mx-green-800 rounded-md shadow-lg max-h-[500px]">
											<ComboboxOption
												v-for="option in batchActionMenuOptions"
												:key="option.id"
												v-slot="{ active, selected }"
												:value="option"
											>
												<div
													class="relative py-2 pl-4 pr-10 cursor-pointer select-none whitespace-nowrap"
													:class="[
														{ 'bg-mx-gray-100 dark:bg-mx-green-600 dark:text-white': active },
														{ 'text-black dark:text-mx-gray-300': !active }
													]"
												>
													<!-- TODO i18n -->
													{{ option.label }}
													<span
														v-if="selected"
														class="absolute inset-y-0 right-0 flex items-center pr-3"
													>
														✓
													</span>
												</div>
											</ComboboxOption>
										</ComboboxOptions>
									</div>
								</Combobox>
							</slot>
							<!-- Per Page Select Menu  -->
							<slot name="perPage">
								<div
									v-if="perPageSelect || tableControls"
									class="flex items-center w-full gap-2 md:w-auto"
								>
									<Menu
										as="div"
										class="relative inline-block w-full text-left group md:w-auto"
									>
										<div>
											<MenuButton
												:class="controlButtonClasses"
												class="inline-flex md:w-36 w-full justify-center md:justify-normal items-center gap-x-1.5 rounded-md bg-mx-gray-100 dark:bg-mx-green-800 px-4 py-2 whitespace-nowrap text-sm font-semibold text-mx-gray-500 dark:text-mx-gray-300 shadow-sm hover:bg-mx-gray-200 dark:hover:bg-mx-green-600"
											>
												<!-- TODO i18n -->
												{{ itemsPerPage }} per page
												<ChevronDownIcon
													class="w-5 h-5 -mr-1.5 text-mx-gray-400"
													aria-hidden="true"
												/>
											</MenuButton>
										</div>
										<Transition
											enter-active-class="transition duration-100 ease-out"
											enter-from-class="transform scale-95 opacity-0"
											enter-to-class="transform scale-100 opacity-100"
											leave-active-class="transition duration-75 ease-in"
											leave-from-class="transform scale-100 opacity-100"
											leave-to-class="transform scale-95 opacity-0"
										>
											<MenuItems class="absolute left-0 z-20 mt-1 origin-top-left bg-white rounded-md shadow-lg min-w-36 dark:bg-mx-green-800 focus:outline-none">
												<div class="first-of-type:rounded-t-md last-of-type:rounded-b-md">
													<MenuItem
														v-for="(option, index) in [25, 50, 100, maximumItemsPerPage]"
														:key="option"
														as="div"
														class="first-of-type:rounded-t-md last-of-type:rounded-b-md"
													>
														<template #default="{ active }">
															<button
																:class="[
																	active ? 'bg-mx-gray-100 dark:bg-mx-green-600 dark:text-white' : 'text-mx-gray-700 dark:text-mx-gray-300',
																	'block w-full px-4 py-2 text-left focus:outline-transparent',
																	{ 'rounded-t-md' : index === 0 },
																	{ 'rounded-b-md' : option === maximumItemsPerPage }
																]"
																@click="userSelectedPerPage = option"
															>
																<!-- TODO i18n -->
																{{ option }} per page
															</button>
														</template>
													</MenuItem>
												</div>
											</MenuItems>
										</Transition>
									</Menu>

									<span
										class="hidden text-mx-gray-500 dark:text-mx-gray-300 whitespace-nowrap md:block"
										:class="tableTextClasses"
									>
										<!-- TODO i18n -->
										{{ totalItems }} results
									</span>
								</div>
							</slot>
						</div>
						<!-- Right Positioned Controls -->
						<div class="flex items-center w-full gap-2 ml-auto md:w-auto">
							<!-- Extra Controls Right Slot -->
							<slot name="extraControlsRight" />
							<!-- Pagination Controls -->
							<slot name="pagination">
								<template v-if="showPagination && totalPages > 1">
									<div class="flex justify-center w-full">
										<div
											class="text-mx-gray-500 dark:text-mx-gray-300 whitespace-nowrap md:hidden"
											:class="tableTextClasses"
										>
											<!-- TODO i18n -->
											{{ totalItems }} results
										</div>
									</div>
									<div class="flex items-center justify-between space-x-2 bg-white rounded-lg dark:bg-mx-green-700">
										<input
											id="currentPage"
											v-model="pageInput"
											:class="[tableTextClasses, pageInputClasses]"
											class="-mr-0.5 text-center rounded-md shadow-inner text-mx-gray-500 dark:text-mx-gray-300 bg-mx-gray-50 dark:bg-mx-green-800 focus:outline-none ring-0"
											type="number"
											:min="1"
											:max="totalPages"
											@focus="pageInputBlurred = false"
											@blur="pageInputBlurred = true"
											@keydown.enter.prevent="handlePageInputEnter"
										>
										</input>
										<label
											for="currentPage"
											class="text-mx-gray-500 dark:text-mx-gray-300 whitespace-nowrap"
											:class="tableTextClasses"
										>
											<!-- TODO i18n -->
											of {{ totalPages }}
										</label>
										<div class="flex items-center">
											<button
												:disabled="currentPage <= 1"
												:class="controlButtonClasses"
												class="p-2 mr-2 rounded-md aspect-square bg-mx-gray-100 dark:bg-mx-green-800 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 disabled:opacity-50 disabled:hover:bg-mx-gray-100 dark:disabled:hover:bg-mx-green-800 disabled:cursor-not-allowed dark:text-mx-gray-400"
												@click="previousPage"
											>
												<ChevronLeftIcon />
											</button>
											<button
												:disabled="currentPage >= totalPages"
												:class="controlButtonClasses"
												class="p-2 rounded-md aspect-square bg-mx-gray-100 dark:bg-mx-green-800 hover:bg-mx-gray-200 dark:hover:bg-mx-green-600 disabled:opacity-50 disabled:cursor-not-allowed dark:disabled:hover:bg-mx-green-800 dark:text-mx-gray-400 disabled:hover:bg-mx-gray-100"
												@click="nextPage"
											>
												<ChevronRightIcon />
											</button>
										</div>
									</div>
								</template>
							</slot>
						</div>
					</div>
				</slot>
				<div
					class="w-full overflow-x-auto"
					:style="`height: ${tableContentHeight}`"
				>
					<table
						ref="tableElement"
						class="w-full h-auto max-w-full border-separate border-none rounded-lg shadow border-spacing-0 dark:bg-mx-green-700"
					>
						<colgroup>
							<!-- Batch Select Checkbox Colgroup -->
							<col
								v-if="batchAction.id"
								style="width: 50px;"
							>
							<col
								v-for="(col, index) in displayedColumns"
								:key="index"
								:span="col.span || 1"
								:style="col.style"
							>
						</colgroup>
						<thead class="rounded-t-lg">
							<tr
								:class="[
									'first-of-type:bg-mx-gray-200 dark:first-of-type:dark:bg-mx-green-800 sticky top-0 z-10',
									{ 'first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg' : !(showTableControlBar) }
								]"
							>
								<!-- Select All Checkbox Header -->
								<th
									v-if="showBatchSelectCheckboxes"
									scope="col"
									:class="[
										'relative text-left border-b border-mx-gray-100 dark:border-mx-green-900 dark:text-mx-gray-300',
										tableColumnClasses,
										{ 'first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg' : !(showTableControlBar) }
									]"
								>
									<!-- TODO i18n -->
									<FormCheckbox
										:id="`${id}-checkbox-row-all`"
										hide-label
										required
										screen-reader-text="Select All"
										variant="secondary"
										:checked="allRowsSelected"
										@change="toggleSelectAll"
									/>
								</th>
								<th
									v-for="column in displayedColumns"
									:key="column.key"
									scope="col"
									:class="[
										'text-left border-b border-mx-gray-100 dark:border-mx-green-900 dark:text-mx-gray-300',
										tableColumnClasses,
										{ 'first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg' : !(showTableControlBar) }
									]"
								>
									<div
										v-if="!column.hidden"
										:class="[
											'inline-flex items-center text-black uppercase dark:text-mx-gray-300 rounded-lg px-2 pt-2 pb-1.5 -ml-2',
											{ 'dark:hover:bg-mx-green-600 hover:bg-mx-gray-300 cursor-pointer' : column?.sortable }
										]"
										@click="handleSort(column)"
									>
										<!-- TODO i18n -->
										<span class="font-bold">
											{{ column.label }}
										</span>
										<span
											v-if="activeSortColumnKey === column.key && column.sortable"
											class="ml-2 -mb-1"
										>
											<BarsArrowUpIcon
												v-if="tableState.sortOrder === 'ASC'"
												class="w-5 h-5"
											/>
											<BarsArrowDownIcon
												v-if="tableState.sortOrder === 'DESC'"
												class="w-5 h-5"
											/>
										</span>
									</div>
								</th>
							</tr>
						</thead>
						<tbody class="rounded-b-lg">
							<template v-if="isLoading">
								<tr
									v-for="i in perPage"
									:key="i"
									class="w-full bg-white border-b border-mx-gray-100 dark:border-mx-green-900 dark:bg-mx-green-800"
								>
									<td
										v-for="j in loadingColumnsCount"
										:key="j"
										:class="[
											{ 'first-of-type:rounded-bl-lg last-of-type:rounded-br-lg' : i === perPage }
										]"
									>
										<div
											:class="[
												'bg-white dark:bg-mx-green-700 animate-pulse self-stretch w-full justify-start items-center inline-flex',
												tableDataClasses,
												{ 'rounded-bl-lg' : j === 1 && i === perPage },
												{ 'rounded-br-lg' : j === perPage && i === perPage }
											]"
										>
											<div class="w-full h-4 rounded bg-mx-gray-200 dark:bg-mx-green-800" />
										</div>
									</td>
								</tr>
							</template>
							<template v-else>
								<tr
									v-for="(row, index) in tableData"
									:id="row.id"
									:key="`${row.id}-${index}`"
									:class="[
										row.rowClasses ? row.rowClasses : 'text-mx-gray-500 dark:text-mx-gray-400',
										'relative w-full border-b border-mx-gray-100 rounded-b-lg dark:border-mx-green-900 group/row'
									]"
								>
									<!-- Row Checkbox -->
									<td
										v-if="showBatchSelectCheckboxes"
										:class="[
											'relative justify-start border-b border-mx-gray-100 dark:border-mx-green-900',
											tableDataClasses,
											{ 'first-of-type:rounded-bl-lg last-of-type:rounded-br-lg' : isLastRow(row) },
											{ 'dark:group-hover/row:bg-mx-green-600 group-hover/row:bg-mx-gray-100' : row?.navigateTo }
										]"
									>
										<FormCheckbox
											:id="`${id}-checkbox-row-${row.id}`"
											hide-label
											required
											screen-reader-text="Select Row"
											:checked="isSelected(row)"
											@change="toggleRowSelection(row, $event)"
										/>
									</td>
									<td
										v-for="column in displayedColumns"
										:key="column.key"
										:class="[
											'justify-start border-b border-mx-gray-100 dark:border-mx-green-900',
											tableDataClasses,
											{ 'first-of-type:rounded-bl-lg last-of-type:rounded-br-lg' : isLastRow(row) },
											{ 'dark:group-hover/row:bg-mx-green-600 group-hover/row:bg-mx-gray-100 cursor-pointer' : row?.navigateTo }
										]"
										:style="column.style"
										:colspan="column.span || 1"
									>
										<NuxtLink
											:to="row?.navigateTo"
											target="_blank"
											:class="[
												'items-center self-stretch justify-start w-full leading-tight',
												tableTextClasses
											]"
										>
											<template v-if="$slots[column.key]">
												<slot
													:name="column.key"
													:row="row"
													:search="search"
													:column="column"
													:format-cell="formatCell"
												/>
											</template>
											<template v-else>
												<span
													class="line-clamp-3"
													:class="tableTextClasses"
												>
													{{ formatCell(row[column.key], column.formatter, row) }}
												</span>
											</template>
										</NuxtLink>
									</td>
									<button
										v-if="row.navigateTo"
										class="absolute px-0.5 py-0.5 text-transparent transform -translate-y-1/2 rounded cursor-pointer group-hover/row:dark:text-mx-gray-400 group/copy group-hover/row:text-mx-gray-400 left-0.5 top-1/2 dark:hover:bg-mx-green-800 hover:bg-mx-gray-300"
										@click="copyLinkToClipboard(row)"
									>
										<DocumentDuplicateIcon class="h-4 group-hover/copy:text-white group-hover/copy:dark:text-mx-gray-300" />
									</button>
									<button
										v-if="allowRowExport"
										class="absolute px-0.5 py-0.5 text-transparent transform -translate-y-1/2 rounded cursor-pointer group-hover/row:dark:text-mx-gray-400 group/copy group-hover/row:text-mx-gray-400 right-0.5 top-1/2 dark:hover:bg-mx-green-800 hover:bg-mx-gray-300"
										@click="copyRowToClipboard(row)"
									>
										<SolidTableCellsIcon class="h-4 group-hover/copy:text-white group-hover/copy:dark:text-mx-gray-300" />
									</button>
								</tr>
							</template>
						</tbody>
					</table>
					<div
						v-if="showEmptyState && !isLoading"
						class="w-full"
					>
						<slot name="empty">
							<TableCellsIcon
								class="w-1/2 max-w-md mx-auto text-mx-gray-300 dark:text-mx-green-600 h-1/2"
								aria-hidden="true"
							/>
							<div class="w-full max-w-sm pb-8 mx-auto -mt-8">
								<div class="text-2xl font-semibold text-center text-mx-gray-400">
									{{ emptyStateMessage }}
								</div>
							</div>
							<div
								v-if="showResetTableButton"
								class="flex justify-center w-full pb-8"
							>
								<button
									v-if="showEmptyState && !isLoading"
									class="h-10 primary-btn"
									@click="resetTable = true"
								>
									<span class="text-base">
										<!-- TODO i18n -->
										Reset Table
									</span>
								</button>
							</div>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	TableCellsIcon,
	BarsArrowDownIcon,
	BarsArrowUpIcon,
	ChevronLeftIcon,
	ChevronRightIcon
} from '@heroicons/vue/24/outline'

import {
	ChevronDownIcon,
	DocumentDuplicateIcon,
	TableCellsIcon as SolidTableCellsIcon
} from '@heroicons/vue/20/solid'

import {
	Combobox,
	ComboboxButton,
	ComboboxOptions,
	ComboboxOption,
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	DisclosurePanel,
	Disclosure,
	DisclosureButton
} from '@headlessui/vue'

import type {
	TableRow,
	TableColumn,
	TableFilter,
	PresetFilter,
	CellSize
} from '@/components/table/types'

import type { BatchAction } from '@/components/table/types'

import { useTable } from '@/composables/useModernTable'
import { PORTAL_BASE } from '@/constants/index.js'
import { useToast } from '@/composables/useToast'
import { copyToClipboard } from '@/composables/copyLinkToClipboard'

const { addToast } = useToast()

const { bugsnagReport } = useErrorReporter()
const { copyHyperlinkToClipboard } = copyToClipboard()

const props = defineProps({
	id: {
		type: String,
		required: true
	},
	columns: {
		type: Array as PropType<(TableColumn)[]>,
		default: () => []
	},
	defaultColumns: {
		type: Array as PropType<(TableColumn)[]>,
		default: () => []
	},
	filters: {
		type: Array as PropType<(TableFilter)[]>,
		default: () => []
	},
	tableData: { // fields must match column keys, must have id
		type: Array as PropType<TableRow[]>,
		required: true
	},
	title: {
		type: String,
		default: 'Table Title'
	},
	emptyStateMessage: {
		type: String,
		default: 'No Table Data Found'
	},
	itemName: {
		type: String,
		default: 'Item'
	},
	isLoading: {
		type: Boolean,
		default: false
	},
	presetsLoading: {
		type: Boolean,
		default: false
	},
	showEmptyState: {
		type: Boolean,
		default: true
	},
	showCreateButton: {
		type: Boolean,
		default: false
	},
	hideHeader: {
		type: Boolean,
		default: false
	},
	searchBar: {
		type: Boolean,
		default: false
	},
	filterable: {
		type: Boolean,
		default: false
	},
	pagination: {
		type: Boolean,
		default: false
	},
	perPageSelect: {
		type: Boolean,
		default: false
	},
	columnSelect: {
		type: Boolean,
		default: false
	},
	multiFilterSelect: {
		type: Boolean,
		default: false
	},
	cellSizeSelect: {
		type: Boolean,
		default: false
	},
	tableControls: {
		type: Boolean,
		default: false
	},
	perPage: {
		type: Number,
		default: 15
	},
	totalItems: {
		type: Number,
		default: 0
	},
	presetFilters: {
		type: Array as PropType<PresetFilter[]>,
		default: () => []
	},
	maximumItemsPerPage: {
		type: Number,
		default: 500
	},
	tableCellSize: {
		type: String as PropType<CellSize>,
		default: 'sm'
	},
	linkColumnKeys: {
		type: Array as PropType<string[]>,
		default: () => []
	},
	linkTextPrefix: {
		type: String,
		default: ''
	},
	linkTextJoin: {
		type: String,
		default: ' | '
	},
	allowTableExport: {
		type: Boolean,
		default: false
	},
	allowRowExport: {
		type: Boolean,
		default: false
	},
	showResetTableButton: {
		type: Boolean,
		default: true
	},
	routeQueries: {
		type: Boolean,
		default: true
	},
	defaultSortOrderDesc: {
		type: Boolean,
		default: false
	},
	batchActionMenuOptions: {
		type: Array as PropType<BatchAction[]>,
		default: () => [
			{ id: 1, label: 'Option 1' },
			{ id: 2, label: 'Option 2' },
			{ id: 3, label: 'Option 3' }
		]
	},
	batchSelect: {
		type: Boolean,
		default: false
	},
	resetBatchSelections: {
		type: Boolean,
		default: false
	},
	componentHeight: {
		type: Number,
		default: 0
	}
})

const {
	handleSearchBlur,
	handleSort,
	formatCell,
	isLastRow,
	showPresetActive,
	addOrRemovePreset,
	addOrRemovePresetOptions,
	showPresetOptionSelected,
	clearActivePresetOptions,
	showMultiFilterReset,
	clearActiveMultiFilters,
	addOrRemoveFilter,
	handleFilterChange,
	resetColumnSelect,
	addOrRemoveColumn,
	previousPage,
	nextPage,
	payload,
	tableState,
	showFilters,
	showColumnReset,
	itemsPerPage,
	presetFiltersWithAllOption,
	activeFilterLabel,
	activePresetOptions,
	displayedColumns,
	showTableControlBar,
	showPagination,
	totalPages,
	cellSize,
	cellSizeOptions,
	tableDataClasses,
	tableColumnClasses,
	tableTextClasses,
	userSelectedCellSize,
	controlButtonClasses,
	controlBarClasses,
	dropdownClasses,
	pageInputClasses,
	setPresetsLoading
} = useTable(props)

const { userSelectedPerPage, currentPage, activeFilterKey, searchQuery, activeSortColumnKey, selectedColumnKeys, activeMultiFilterIds, resetTable } = toRefs(tableState.value)

const { tableData, linkTextPrefix, linkTextJoin, linkColumnKeys, filters, perPage, batchActionMenuOptions, batchSelect, resetBatchSelections, componentHeight, presetsLoading } = toRefs(props)

const emit = defineEmits([
	'open-create-modal',
	'active-preset-options',
	'fetch-table-data',
	'cell-size-change',
	'selection-change',
	'batch-action-selected',
	'start-batch-action-workflow',
	'columns-change',
	'batch-select-reset'
])

const roughTableHeaderHeight = 212
const tableContentHeight = computed(() => {
	return componentHeight.value > roughTableHeaderHeight ? `${componentHeight.value - roughTableHeaderHeight}px` : ''
})

const tableElement = ref<HTMLTableElement | null>(null)

const pageInput = ref(1)
const pageInputBlurred = ref(false)

const searchQueryValue = ref('')
const searchQueryColumnKey = ref('')
const searchableColumns = computed(() => displayedColumns.value?.filter(column => column.searchable))
const showBatchSelectCheckboxes = computed(() => batchSelect.value && batchAction.value.id !== '')

/* TODO i18n */
const multiFilterLabel = computed(() => {
	// the count of active multi filters ids that are also in the filters array
	const activeMultiFilterCount = activeMultiFilterIds.value.filter(id => filters.value.map(filter => filter.id).includes(id)).length
	if (activeMultiFilterCount === 1) {
		return '1 Filter Selected'
	}
	return activeMultiFilterCount > 0 ? `${activeMultiFilterCount} Filters Selected` : 'Filter Select'
})

const presetLabel = (label: string) => {
	// split label at : and return the first part
	return label.split(':')[0] || label
}

const presetActiveLabel = (label: string) => {
	// split label at : and return the second part
	return label.split(':')[1] || ''
}

const handleResetMultiFilters = () => {
	clearActiveMultiFilters()
	clearActivePresetOptions()
}

const copyTablePageToClipboard = async () => {
	if (!tableElement.value) { return }

	const headers = displayedColumns.value.map(row => row.label)
	const newColumnIds = displayedColumns.value.map(row => row.key)
	const rows: string[][] = []

	tableData.value.forEach((row) => {
		rows.push(newColumnIds.map(key => row[key]))
	})

	// Create HTML for headers and rows
	const headersHTML = `<tr>${headers.map(header => `<th>${header}</th>`).join('')}</tr>`
	const rowsHTML = rows.map(row => `<tr>${row.map(cell => `<td>${cell}</td>`).join('')}</tr>`).join('')
	const tableHTML = `<table>${headersHTML}${rowsHTML}</table>`

	// Create plain text format for headers and rows
	const plainText = headers.join('\t') + '\n' + rows.map(row => row.join('\t')).join('\n')

	try {
		await navigator.clipboard.write([
			new ClipboardItem({
				'text/html': new Blob([ tableHTML ], { type: 'text/html' }),
				'text/plain': new Blob([ plainText ], { type: 'text/plain' })
			})
		])
		/* TODO i18n */
		addToast({
			title: 'Table Copied to Clipboard!',
			notificationType: 'success'
		})
	} catch (err) {
		bugsnagReport({
			error: new Error('Failed to copy table to clipboard'),
			showToast: true,
			/* TODO i18n */
			toast: {
				title: 'Failed to Copy Table to Clipboard',
				notificationType: 'error'
			}
		})
	}
}

const copyRowToClipboard = async (row: TableRow) => {
	if (!tableElement.value) { return }

	// Get headers - use a Set to remove duplicates then convert back to an array
	const headers = [ ...new Set(tableData.value.flatMap(row => Object.keys(row))) ].filter(key => key !== 'rowClasses' && key !== 'navigateTo')

	// get the row as an array and filter out the navigateTo and rowClasses keys
	const rowArray = Object.values(row).filter(cell => cell !== row.navigateTo && cell !== row.rowClasses)

	// Create HTML for headers and row
	const headersHTML = `<tr>${headers.map(header => `<th>${header}</th>`).join('')}</tr>`
	const rowHTML = `<tr>${rowArray.map(cell => `<td>${cell}</td>`).join('')}</tr>`
	const tableHTML = `<table>${headersHTML}${rowHTML}</table>`

	const rowObjectWithKeysDisplayedAsString = headers.map((header, index) => `${header}: ${rowArray[index]}`).join(' | ')

	try {
		await navigator.clipboard.write([
			new ClipboardItem({
				'text/html': new Blob([ tableHTML ], { type: 'text/html' }),
				'text/plain': new Blob([ headers.join('\t') + '\n' + rowArray.join('\t') ], { type: 'text/plain' })
			})
		])
		/* TODO i18n */
		addToast({
			title: 'Row Copied to Clipboard!',
			message: rowObjectWithKeysDisplayedAsString,
			notificationType: 'success'
		})
	} catch (err) {
		bugsnagReport({
			error: new Error(`Failed to copy row to clipboard ${rowObjectWithKeysDisplayedAsString}`),
			showToast: true,
			toast: {
				/* TODO i18n */
				title: 'Failed to Copy Row to Clipboard',
				message: rowObjectWithKeysDisplayedAsString,
				notificationType: 'error'
			}
		})
	}
}

const copyLinkToClipboard = (row: TableRow) => {
	const linkUrl = `${PORTAL_BASE}${row.navigateTo}`
	const displayText = formatLinkDisplayText(row)
	copyHyperlinkToClipboard({ linkUrl, displayText })
}

const isPhoneNumber = (value: string) => {
	const phoneRegex = /^\d{10}$/
	const phoneWithParensRegex = /^\(\d{3}\)\s\d{3}-\d{4}$/
	return phoneRegex.test(value) || phoneWithParensRegex.test(value)
}

/* TODO i18n */
const formatLinkDisplayText = (row: TableRow) => {
	// If there are no link columns, return the default link text
	if (linkColumnKeys.value?.length === 0) {
		return 'Maverix Link'
	}
	// use a Set to keep the order from the linkColumnKeys array and get the keys of the columns that are in the linkColumnKeys array
	// then filter out the navigateTo column
	const keys = [ ...new Set(linkColumnKeys.value.filter(key => key !== 'navigateTo' && key !== 'rowClasses')) ]

	// Get the values of the link columns
	// and join them with the linkTextJoin value
	const displayText = keys.map((key) => {
		let rowValue = row?.[key]
		// if the value is a phone number, prepend 'tel:' to bypass slack's automatic phone number formatting
		if (isPhoneNumber(rowValue)) {
			rowValue = `tel:${rowValue}`
		}
		return `${rowValue}`
	}).join(linkTextJoin.value)

	// Return the link text prefix and the display text
	return linkTextPrefix.value + displayText
}

const search = (columnKey: string, value: string) => {
	if (columnKey && value) {
		searchQueryColumnKey.value = columnKey
		searchQueryValue.value = value
		searchQuery.value = `${columnKey}: ${value}`
	} else {
		searchQueryColumnKey.value = ''
		searchQueryValue.value = ''
		searchQuery.value = ''
	}
}

const searchBlurred = () => {
	if (searchQueryValue.value && searchQueryColumnKey.value) {
		searchQuery.value = searchQueryValue.value ? `${searchQueryColumnKey.value}: ${searchQueryValue.value}` : ''
	}
	if (!searchQueryValue.value && searchQueryColumnKey.value) {
		searchQueryColumnKey.value = ''
		searchQueryValue.value = ''
		searchQuery.value = ''
	}
	handleSearchBlur()
}

const pageInputValid = computed(() =>
	pageInput.value > 0 && pageInput.value !== currentPage.value && pageInput.value <= totalPages.value
)

const resetPageInput = () => {
	if (totalPages.value === 0 || pageInput.value < 1 || !pageInputValid.value) {
		pageInput.value = currentPage.value
	}
}

const handlePageInputEnter = () => {
	if (pageInputValid.value) {
		tableState.value.currentPage = pageInput.value
	} else {
		resetPageInput()
	}
}

const batchAction = defineModel<BatchAction>({ default: { id: '', text: '' } })

const loadingColumnsCount = computed(() => {
	return (displayedColumns.value.length + (batchAction.value.id ? 1 : 0)) || perPage
})

const selectedRows = ref<TableRow[]>([])

const isSelected = (row: TableRow) => {
	return selectedRows.value.some(selectedRow => selectedRow.id === row.id)
}

const toggleRowSelection = (row: TableRow, event: Event) => {
	const checkbox = event.target as HTMLInputElement
	if (checkbox.checked) {
		selectedRows.value.push(row)
	} else {
		selectedRows.value = selectedRows.value.filter(selectedRow => selectedRow.id !== row.id)
	}
}

const allRowsSelected = computed(() => {
	return tableData.value.length > 0 && selectedRows.value.length === tableData.value.length
})

const toggleSelectAll = () => {
	if (allRowsSelected.value) {
		selectedRows.value = []
	} else {
		selectedRows.value = [ ...tableData.value ]
	}
}

const clearSelectedRows = () => {
	selectedRows.value = []
}

const emitStartBatchAction = () => {
	emit('start-batch-action-workflow', { action: batchAction.value, items: selectedRows.value })
}

watch(resetBatchSelections, () => {
	clearSelectedRows()
	batchAction.value = { id: '', label: '' }
	emit('batch-select-reset')
})

watch(batchAction, (newValue) => {
	if (newValue.id) {
		emit('batch-action-selected', newValue)
	}
})

watch(selectedRows, (newValue) => {
	emit('selection-change', newValue)
})

watch(userSelectedPerPage, () => {
	clearSelectedRows()
})

watch(displayedColumns, () => {
	emit('columns-change', displayedColumns.value)
})

watch(pageInputBlurred, () => {
	if (pageInput.value !== currentPage.value && pageInputBlurred.value) {
		if (pageInputValid.value) {
			tableState.value.currentPage = pageInput.value
		} else {
			resetPageInput()
		}
	}
})

watch(totalPages, () => {
	if (currentPage.value > totalPages.value) { // if the current page is greater than the total pages
		// And if there are no pages, set the current page to 1, otherwise set it to the last page
		tableState.value.currentPage = totalPages.value === 0 ? 1 : totalPages.value
	}
})

watch(currentPage, () => {
	if (pageInput.value !== currentPage.value) {
		pageInput.value = currentPage.value
	}
})

watch(searchQueryColumnKey, () => {
	if (searchQueryColumnKey.value) {
		searchQuery.value = searchQuery.value ? `${searchQueryColumnKey.value}: ${searchQueryValue.value}` : ''
		searchBlurred()
	}
}, { immediate: true })

watch(cellSize, () => {
	emit('cell-size-change', cellSize.value)
})

const emitOpenCreateModal = () => emit('open-create-modal')

watch(payload, () => {
	if (payload.value) {
		const waitForPresetsLoaded = new Promise((resolve) => {
			watch(presetsLoading, (val) => {
				if (!val) {
					resolve(true)
				}
			}, { immediate: true })
		})
		waitForPresetsLoaded.then(() => {
			setPresetsLoading(false)
			nextTick(() => {
				emit('fetch-table-data', payload.value)
			})
		})
	}
}, { deep: true, immediate: true })

watch(activePresetOptions, () => {
	emit('active-preset-options', activePresetOptions.value)
}, { deep: true, immediate: true })
</script>

<style scoped>
th {
	resize: horizontal;
	overflow: auto;
}

/* WebKit Browsers */
/* width */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #6A6A6A;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #ADADAD;
}

/* Remove scrollbar buttons */
::-webkit-scrollbar-button {
	display: none;
}

/* Firefox Browsers */
* {
	scrollbar-width: thin;
	scrollbar-color: #6A6A6A transparent;
}

/* Hide the spinner (number input arrows) */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
