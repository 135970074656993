import { ref, computed, watch } from 'vue'
import type { SortOrder } from '@/utils/sortMethods/types'
import type {
	TableRow,
	TableColumn,
	TableFilter,
	PresetFilter,
	CellSize,
	BatchAction
} from '@/components/table/types'
import { useRoute, useRouter } from 'vue-router'

interface SearchValues {
	[key: string]: any
}

interface TableState {
	tableData: TableRow[]
	selectedColumnKeys: string[] // user selected keys for columns to display
	currentPage: number
	userSelectedPerPage: number | null
	activeMultiFilterIds: string[]
	activeSortColumnKey: string
	activeFilterKey: string
	sortOrder: SortOrder
	searchQuery: string
	isLoading: boolean
	showEmptyState: boolean
	resetTable: boolean // reset the table to the default state
	searchValues: SearchValues
	searchQueryFilterParam: string
}

interface TableProps {
	id: string
	tableData: TableRow[]
	showCreateButton: boolean
	hideHeader: boolean
	searchBar: boolean
	filterable: boolean // TODO: keep this?
	pagination: boolean
	perPageSelect: boolean
	columnSelect: boolean
	multiFilterSelect: boolean
	tableControls: boolean // enable all controls
	totalItems: number
	perPage: number
	presetFilters: PresetFilter[]
	filters: TableFilter[]
	title: string
	emptyStateMessage: string
	itemName: string
	columns: TableColumn[] // all possible columns
	defaultColumns: TableColumn[] // default columns to display
	maximumItemsPerPage: number
	tableCellSize: CellSize
	linkColumnKeys: string[] // keys to include in the link display text (if any) when a row has a navigateTo property (for easy copy/paste)
	linkTextPrefix: string // text to prepend to the link display text
	linkTextJoin: string // text to append to the link display text keys
	routeQueries: boolean // enables query string parameters in the route
	defaultSortOrderDesc: boolean
	batchSelect: boolean
	batchActionMenuOptions: BatchAction[]
	resetBatchSelections: boolean // resets batch selections and batch action menu options
}

interface TableApiParams {
	page: number
	limit: number
	sortColumn: string
	sortOrder: SortOrder
	searchValues: SearchValues
}

interface TableHelpers {
	tableState: Ref<TableState>
	payload: ComputedRef<TableApiParams>
	showTableControlBar: ComputedRef<Ref<boolean>>
	showPagination: ComputedRef<Ref<boolean>>
	activeFilterLabel: ComputedRef<string>
	presetFiltersWithAllOption: ComputedRef<PresetFilter[]>
	activePresetOptions: Ref<(TableFilter | undefined)[] | undefined>
	itemsPerPage: ComputedRef<number>
	showFilters: ComputedRef<boolean>
	showColumnReset: ComputedRef<boolean>
	totalPages: ComputedRef<number>
	displayedColumns: ComputedRef<TableColumn[] | never[]> // columns to display
	cellSize: ComputedRef<CellSize>
	cellSizeOptions: CellSize[]
	tableDataClasses: ComputedRef<string>
	tableColumnClasses: ComputedRef<string>
	tableTextClasses: ComputedRef<string>
	userSelectedCellSize: Ref<CellSize | ''>
	controlButtonClasses: ComputedRef<string>
	controlBarClasses: ComputedRef<string>
	dropdownClasses: ComputedRef<string>
	pageInputClasses: ComputedRef<string>

	tableControls?: Ref<boolean>
	allFilters?: TableFilter[]
	allFilterIds?: string[]
}

interface TableMethods {
	resetToFirstPage: () => void
	setQueryStringValues: () => void
	handleSearchBlur: () => void
	handleSort: (column: TableColumn | undefined) => void
	handleFilterChange: (id: string) => void
	formatCell: (value: any, formatter?: (value: any, row: TableRow) => string, row?: TableRow) => string
	isLastRow: (row: TableRow) => boolean
	resetColumnSelect: () => void
	addOrRemoveColumn: (columnKey: string) => void
	addOrRemoveFilter: (filterId: string) => void
	addOrRemovePresetOptions: (optionId: string, filter?: PresetFilter) => void
	addOrRemovePreset: ({ filters, columnIds }: {filters?: TableFilter[], columnIds?: string[]}) => void
	showPresetActive: (activeFilters?: TableFilter[]) => boolean
	showPresetOptionSelected: (optionId: string, options?: TableFilter[]) => boolean
	showMultiFilterReset: () => boolean
	clearActiveMultiFilters: () => void
	clearActivePresetOptions: () => void
	nextPage: () => void
	previousPage: () => void
	setPresetsLoading: (loading: boolean) => void

	clearPresetOptions?: (options: TableFilter[]) => void
	showPresetReset?: (options: TableFilter[]) => boolean
	pushRouteQueries?: () => void
	removeSearchQueryFromSearchValues?: (oldQuery: string) => void
	removeActiveMultiFiltersFromSearchValues?: (oldFilters: string[]) => void
	setQueryStringFilters?: () => void
	setQueryStringPerPage?: () => void
	setQueryStringSort?: () => void
	setQueryStringSortOrder?: () => void
	setQueryStringSearch?: () => void
	setQueryStringPage?: () => void
	setQueryStringColumns?: () => void
	setQueryStringSearchFilterParams?: () => void
	setSearchValues?: () => void
	clearQueryStrings?: () => void
	addSearchQueryToSearchValues?: () => void
	stringToBoolean?: (value: string) => boolean | string
	camelCase?: (value: string) => string
}

export function useTable (props: TableProps) : TableHelpers & TableMethods {
	const route = useRoute()
	const router = useRouter()
	const {
		/*
		title,
		emptyStateMessage,
		itemName,
		showCreateButton,
		hideHeader,
		*/
		id,
		searchBar,
		filterable,
		pagination,
		perPageSelect,
		columnSelect,
		multiFilterSelect,
		tableControls,
		filters,
		presetFilters,
		totalItems,
		columns,
		defaultColumns,
		perPage,
		routeQueries,
		defaultSortOrderDesc
	} = toRefs(props)

	const activePresetOptions = ref<(TableFilter | undefined)[]>()

	const DEFAULT_ACTIVE_SORT_COLUMN_KEY = 'id'

	const defaultSortOrder = computed(() => defaultSortOrderDesc?.value ? 'DESC' : 'ASC')

	const tableState = ref<TableState>({
		tableData: props.tableData,
		currentPage: 1,
		userSelectedPerPage: null,
		activeMultiFilterIds: [],
		activeSortColumnKey: DEFAULT_ACTIVE_SORT_COLUMN_KEY,
		activeFilterKey: '',
		sortOrder: defaultSortOrder.value,
		searchQuery: '',
		selectedColumnKeys: defaultColumns.value.map(column => column.key.toString()),
		isLoading: false,
		showEmptyState: false,
		resetTable: false,
		searchValues: {},
		searchQueryFilterParam: ''
	})

	const payload = computed(() : TableApiParams => {
		return {
			page: tableState.value.currentPage,
			limit: itemsPerPage.value,
			sortColumn: tableState.value.activeSortColumnKey.toString().replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`),
			sortOrder: tableState.value.sortOrder,
			searchValues: tableState.value.searchValues
		}
	})

	const totalPages = computed(() => {
		return Math.ceil(totalItems.value / itemsPerPage.value)
	})

	const itemsPerPage = computed(() => {
		if (!tableControls && !pagination) {
			return totalItems.value
		}
		return tableState.value.userSelectedPerPage || perPage.value
	})

	const allFilters = computed(() => {
		const presetFilterOptions = presetFilters.value?.map(({ options }) => {
			return options || []
		}).flat() || []
		const childOptions = presetFilterOptions?.map(({ children }) => {
			return children || []
		}).flat() || []
		return [ ...filters.value, ...presetFilterOptions, ...childOptions ]
	})

	const showTableControlBar = computed(() => {
		return searchBar || showFilters.value || multiFilterSelect || columnSelect || perPageSelect || tableControls || pagination
	})

	const showPagination = computed(() => {
		return pagination || tableControls
	})

	const activeFilterLabel = computed(() => {
		const activeFilter = filters.value?.find(f => f.id === tableState.value.activeFilterKey)
		return activeFilter?.label || 'Filters'
	})

	const presetFiltersWithAllOption = computed(() => {
		return presetFilters.value?.map(filter => (
			filter?.options && filter?.allOption ? { ...filter, options: [ { id: 'All', label: 'All', columnKey: filter?.id, matches: 'All' }, ...filter.options ] } : filter
		))
	})

	const displayedColumns = computed(() => {
		return columns.value?.filter(column => tableState.value.selectedColumnKeys?.includes(column.key as string)) // TODO: check if this is correct
	})

	const showFilters = computed(() => {
		return filters.value?.length > 0 && (filterable || tableControls) && !multiFilterSelect
	})

	const showColumnReset = computed(() => {
		return tableState.value.selectedColumnKeys?.length !== defaultColumns.value?.length
	})

	const allFilterIds = computed(() => {
		return filters.value?.map(({ id }) => id)
	})

	const handleSearchBlur = () => {
		addSearchQueryToSearchValues()
	}

	const handleSort = (column: TableColumn | undefined) => {
		if (column && column.sortable) {
			if (tableState.value.activeSortColumnKey === column.key) {
				tableState.value.sortOrder = tableState.value.sortOrder === 'ASC' ? 'DESC' : 'ASC'
			} else {
				tableState.value.activeSortColumnKey = column.key.toString()
				tableState.value.sortOrder = 'ASC'
			}
		}
	}

	const handleFilterChange = (id: string) => {
		tableState.value.activeFilterKey = id
	}

	const formatCell = (value: any, formatter?: (value: any, row: TableRow) => string, row?: TableRow) => {
		return formatter ? formatter(value, row || {}) : value
	}

	const isLastRow = (row: TableRow) => {
		return row.id === tableState.value.tableData?.[tableState.value.tableData?.length - 1]?.id
	}

	const resetColumnSelect = () => {
		tableState.value.selectedColumnKeys = defaultColumns.value?.map(column => column.key.toString())
	}

	const addOrRemoveColumn = (columnKey: string) => {
		if (tableState.value.selectedColumnKeys?.includes(columnKey)) {
			tableState.value.selectedColumnKeys = tableState.value.selectedColumnKeys?.filter(key => key !== columnKey)
		} else {
			tableState.value.selectedColumnKeys = [ ...tableState.value.selectedColumnKeys, columnKey ]
		}
	}

	const addOrRemoveFilter = (filterId: string) => {
		const { activeMultiFilterIds } = tableState.value
		const filter = filters.value?.find(f => f.id === filterId)

		if (activeMultiFilterIds?.includes(filterId)) {
			// Remove filter if it's already active
			tableState.value.activeMultiFilterIds = activeMultiFilterIds.filter(id => id !== filterId)
		} else if (filter?.columnKey) {
			// Remove any active filters with the same columnKey and add the new filter
			const duplicateFilters = filters.value?.filter(f => f.columnKey === filter.columnKey).map(f => f.id)
			tableState.value.activeMultiFilterIds = [
				...activeMultiFilterIds.filter(id => !duplicateFilters.includes(id)),
				filterId
			]
		} else {
			// Add the filter if it has no columnKey
			tableState.value.activeMultiFilterIds = [ ...activeMultiFilterIds, filterId ]
		}
	}

	const removeAllOptionIdsThatExistByFilter = (filter: PresetFilter) => {
		tableState.value.activeMultiFilterIds = tableState.value.activeMultiFilterIds.filter(id => !filter.options?.find(option => option.id === id))
	}

	const addOrRemovePresetOptions = (optionId: string, filter?: PresetFilter) => {
		// if the filter has preventIntersections, remove all other filters with the same id that also have preventIntersections
		tableState.value.activeMultiFilterIds = tableState.value.activeMultiFilterIds.filter(id => !filters.value?.filter(filter => filter.preventIntersections)?.map(f => f.id).includes(id))

		if (optionId === 'All') {
			clearPresetOptions(filter?.options || [])
			return
		}

		if (tableState.value.activeMultiFilterIds?.includes(optionId)) {
			tableState.value.activeMultiFilterIds = tableState.value.activeMultiFilterIds?.filter(id => id !== optionId)
			return
		}

		if (filter?.multiSelect === false) { // if filter.multiSelect is false,
			// remove any other ids from filter.options that may be present in the activeMultiFilterIds array
			// and then add the new optionId to the activeMultiFilterIds array
			removeAllOptionIdsThatExistByFilter(filter)
		}

		// now add the new optionId to the activeMultiFilterIds array
		tableState.value.activeMultiFilterIds = [ ...tableState.value.activeMultiFilterIds, optionId ]

		filter?.columnIds?.length ? tableState.value.selectedColumnKeys = filter.columnIds : resetColumnSelect()
	}

	const addOrRemovePreset = ({ filters, columnIds }: {filters?: TableFilter[], columnIds?: string[]}) => {
		const tempActiveFilters: string[] = []

		if (showPresetActive(filters)) {
			// clear all filters
			tableState.value.activeMultiFilterIds = tempActiveFilters
			resetColumnSelect()
		} else {
			// add all filters from activePresetOptions to tempActiveFilters
			activePresetOptions.value?.forEach((option) => {
				if (option?.id) {
					tempActiveFilters.push(option.id)
				}
			})

			// add all filters from the preset arg to the activeMultiFilterIds array
			filters?.forEach((filter) => {
				tempActiveFilters.push(filter.id)
			})

			// set the activeMultiFilterIds to the tempActiveFilters array
			tableState.value.activeMultiFilterIds = tempActiveFilters

			// set the selected columns to the columnIds from the preset
			columnIds?.length ? tableState.value.selectedColumnKeys = columnIds : resetColumnSelect()

			// if the first filter has preventIntersections, set the activeMultiFilterIds to only that filter
			if (filters?.[0]?.preventIntersections) {
				tableState.value.activeMultiFilterIds = [ filters?.[0]?.id ]
			}
		}
	}

	const showPresetActive = (activeFilters?: TableFilter[]) => {
		// Early return if no filters are active
		if (!activeFilters) { return false }

		// Check if all active filters are in the preset list
		// Using a Set prevents duplicates and is more performant when checking for existence
		const presetFilterIds = new Set(tableState.value.activeMultiFilterIds?.filter(filterId => allFilterIds.value?.includes(filterId)))

		// Check if every active filter is in the preset list
		return activeFilters.every(filter => presetFilterIds.has(filter.id))
	}

	const showPresetOptionSelected = (optionId: string, options?: TableFilter[]) => {
		return tableState.value.activeMultiFilterIds?.includes(optionId) || (!showPresetReset(options || []) && optionId === 'All')
	}

	const showPresetReset = (options: TableFilter[]) => {
		const optionChildren = options.map(({ children }) => children || []).flat()
		return [ ...options, ...optionChildren ]
			.map(({ id }) => tableState.value.activeMultiFilterIds.includes(id))
			.some(Boolean)
	}

	const showMultiFilterReset = () => {
		return !!tableState.value.activeMultiFilterIds?.find(filter => filters.value?.find(f => f.id === filter))
	}

	const clearActiveMultiFilters = () => {
		const clearedFilters = tableState.value.activeMultiFilterIds.filter((filter) => {
			return !filters.value?.find(f => f.id === filter)
		})
		tableState.value.activeMultiFilterIds = clearedFilters
	}

	const clearPresetOptions = (options: TableFilter[]) => {
		const clearedFilters = tableState.value.activeMultiFilterIds?.filter((filter) => {
			return !options.find(f => f.id === filter)
		})
		tableState.value.activeMultiFilterIds = clearedFilters
	}

	const clearActivePresetOptions = () => {
		activePresetOptions.value = []
	}

	const nextPage = () => {
		if (tableState.value.currentPage < totalPages.value) {
			tableState.value.currentPage++
		}
	}

	const previousPage = () => {
		if (tableState.value.currentPage > 1) {
			tableState.value.currentPage--
		}
	}

	const presetsLoading = ref(true)

	const setPresetsLoading = (loading: boolean) => {
		presetsLoading.value = loading
	}

	const setSearchValues = async () => {
		// add filters to searchValues object
		if (tableState.value.activeMultiFilterIds?.length) {
				// for each filter, check if the filter matches the id of an object in allFilters
				// if it does, add an object with properties of each filter name with the matches value to the return object's properties
				// if it doesn't, return an empty object
				interface AccType {
					[key: string]: string | boolean
				}

				const waitForPresetsLoaded = new Promise((resolve) => {
					watch(presetsLoading, (val) => {
						if (!val) {
							resolve(true)
						}
					}, { immediate: true })
				})

				await waitForPresetsLoaded

				tableState.value.searchValues = tableState.value.activeMultiFilterIds?.reduce((acc: AccType, id) => {
					const filter = allFilters.value?.find(f => f.id === id)
					if (filter && filter?.columnKey) {
						acc[filter.columnKey] = filter.matches // add the columnKey and matches value to the return object
					}
					// check if the filter is a string "true" or "false" and convert it to a boolean
					if (filter && filter?.columnKey && filter?.matches === 'true') {
						acc[filter.columnKey] = true
					}
					if (filter && filter?.columnKey && filter?.matches === 'false') {
						acc[filter.columnKey] = false
					}
					return acc
				}, {})
		}

		// add the search query to the searchValues object
		addSearchQueryToSearchValues()
	}

	const addSearchQueryToSearchValues = () => {
		if (tableState.value.searchQuery) {
			// split the search query into an array of key:value pairs
			// the key is the column key and the value is the search query
			// remove the spaces in the the column key and convert to camelCase
			// then add the key:value pair to the searchValues object

			const searchQueryArray = tableState.value.searchQuery?.split(':')
			tableState.value.searchQueryFilterParam = `${searchQueryArray[0]?.trim().toLocaleLowerCase()}+${searchQueryArray[1]?.trim().toLocaleLowerCase()}`
			const camelCasedColumnKey = camelCase(searchQueryArray[0]?.trim())
			const value = searchQueryArray[1]?.trim().toLocaleLowerCase()
			tableState.value.searchValues[camelCasedColumnKey] = stringToBoolean(value)
		} else {
			// if there is no search query, clear the searchValues object for the search query
			tableState.value.searchQueryFilterParam = ''
		}
	}

	const setQueryStringFilters = () => {
		if (!route.query?.filters) {
			tableState.value.activeMultiFilterIds = []
			return
		}
		if (route.query?.filters) {
			if (Array.isArray(route.query.filters)) {
				tableState.value.activeMultiFilterIds = route.query.filters as string[] || []
			} else if (route.query.filters) {
				tableState.value.activeMultiFilterIds = [ route.query.filters ]
			}
		}
	}

	const setQueryStringPerPage = () => {
		if (route.query?.perPage) {
			tableState.value.userSelectedPerPage = parseInt(route.query.perPage as string)
		}
	}

	const setQueryStringSort = () => {
		if (route.query?.sortColumn) {
			tableState.value.activeSortColumnKey = route.query.sortColumn as string
		}
		if (route.query?.sortOrder) {
			tableState.value.sortOrder = route.query.sortOrder as SortOrder
		}
	}

	const setQueryStringSortOrder = () => {
		if (route.query?.sortOrder) {
			tableState.value.sortOrder = route.query.sortOrder as SortOrder
		}
	}

	const setQueryStringSearch = () => {
		if (route.query?.searchQuery) {
			tableState.value.searchQuery = route.query.searchQuery as string
		}
	}

	const setQueryStringPage = () => {
		if (route.query?.page) {
			tableState.value.currentPage = parseInt(route.query.page as string)
		}
	}

	const setQueryStringColumns = () => {
		if (route.query?.columns) {
			tableState.value.selectedColumnKeys = route.query.columns as string[]
		}
	}

	const setQueryStringSearchFilterParams = () => {
		if (route.query?.searchQuery) {
			const mappedSearchQuery = (route.query.searchQuery as string).replace('+', ': ')
			tableState.value.searchQuery = mappedSearchQuery
		} else {
			tableState.value.searchQuery = ''
		}
	}

	const setQueryStringValues = () => {
		if (routeQueries.value) {
			setQueryStringFilters()
			setQueryStringPerPage()
			setQueryStringSort()
			setQueryStringSortOrder()
			setQueryStringSearch()
			setQueryStringPage()
			setQueryStringColumns()
			setQueryStringSearchFilterParams()
		}
	}

	const pushRouteQueries = () => {
		if (routeQueries.value) {
			router.push({
				query: {
					filters: tableState.value.activeMultiFilterIds,
					page: tableState.value.currentPage,
					perPage: itemsPerPage.value,
					searchQuery: tableState.value.searchQueryFilterParam,
					sortColumn: tableState.value.activeSortColumnKey,
					sortOrder: tableState.value.sortOrder,
					columns: tableState.value.selectedColumnKeys
				}
			})
		}
	}

	const resetToFirstPage = () => {
		tableState.value.currentPage = 1
	}

	const clearQueryStrings = () => {
		tableState.value.activeMultiFilterIds = []
		tableState.value.currentPage = 1
		tableState.value.userSelectedPerPage = perPage.value
		tableState.value.activeSortColumnKey = DEFAULT_ACTIVE_SORT_COLUMN_KEY
		tableState.value.sortOrder = defaultSortOrder.value
		tableState.value.searchQuery = ''
		tableState.value.searchQueryFilterParam = ''
		tableState.value.selectedColumnKeys = defaultColumns.value?.map(column => column.key.toString())
	}

	const stringToBoolean = (value: string) => { // the table uses string values for true and false but the api expects boolean values
		if (value === 'true') {
			return true
		} else if (value === 'false') {
			return false
		}
		return value
	}

	const camelCase = (value: string) => {
		return value.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
			return index === 0 ? word.toLowerCase() : word.toUpperCase()
		}).replace(/\s+/g, '')
	}

	const removeSearchQueryFromSearchValues = (oldQuery: string) => {
		// clear the old searchQuery objects from the searchValues object
		const searchQueryArray = oldQuery.split(':')
		const camelCasedColumnKey = camelCase(searchQueryArray[0].trim())
		delete tableState.value.searchValues[camelCasedColumnKey]
	}

	const removeActiveMultiFiltersFromSearchValues = (oldFilters: string[]) => {
		// clear the old filters from the searchValues object
		oldFilters.forEach((filter) => {
			const filterObject = allFilters.value?.find(f => f.id === filter)
			if (filterObject && filterObject?.columnKey) {
				delete tableState.value.searchValues[filterObject.columnKey]
			}
		})
	}

	watch(() => tableState.value.resetTable, (newValue: boolean) => {
		if (newValue) {
			clearQueryStrings()
			tableState.value.resetTable = false
		}
	})

	watch(() => tableState.value.searchQuery, (newValue, oldValue) => {
		if (newValue !== oldValue && oldValue !== undefined) {
			removeSearchQueryFromSearchValues(oldValue)
		}
		setSearchValues()
	}, { immediate: true })

	watch(() => tableState.value.activeMultiFilterIds, (newValue, oldValue) => {
		if (newValue !== oldValue && oldValue !== undefined) {
			removeActiveMultiFiltersFromSearchValues(oldValue)
		}
		setSearchValues()
		pushRouteQueries()
	})

	watch(() => tableState.value.searchQueryFilterParam, () => {
		pushRouteQueries()
	})

	watch(() => tableState.value.selectedColumnKeys, () => {
		pushRouteQueries()
	})

	watch(() => [ presetFilters, tableState.value.activeMultiFilterIds ], () => { // watch both presetFilters (for first load) and activeMultiFilterIds (for changes after first load)
		// presetFilters.options can come from the api so we need to watch deeply to detect changes to the "options" array since they may not be populated yet when the activeMultiFilterIds array is updated from the route query

		// First find all objects in the presetFilters array that have a property of "filters" (array) that contains an object that has a property of "id" (string) that matches a value in the activeMultiFilterIds array
		const activePresets = presetFilters.value?.filter(filter => filter?.filters?.find(f => tableState.value.activeMultiFilterIds?.includes(f.id)))
		const activePresetFilters = activePresets?.map(filter => filter?.filters)?.flat()

		// find all objects in the presetFilters array that have a property of "options" (array) that contains an object that has a property of "label" (string) that matches a value in the activeMultiFilterIds array
		const presetFiltersWithOptions = presetFilters.value?.filter(filter => filter?.options?.some(option => tableState.value.activeMultiFilterIds?.includes(option.id)))

		const presetFiltersWithOptionsWithChildren = presetFilters.value?.filter(filter => filter?.options?.filter(option => !!option.children?.length)?.length).map(filter => filter?.options?.filter(option => !!option.children?.length))

		const activeChildren = presetFiltersWithOptionsWithChildren?.map(filter => filter?.filter(option => option?.children?.some(child => tableState.value.activeMultiFilterIds?.includes(child.id))))?.map(filter => filter?.map(option => option?.children?.filter(child => tableState.value.activeMultiFilterIds?.includes(child.id)))?.flat())?.flat()

		// then return all objects in activePresets.filters and presetFilter.options that has a property of id that matches any value in the activeMultiFilterIds array
		activePresetOptions.value = [ ...activePresetFilters, ...presetFiltersWithOptions.map(filter => filter?.options?.filter(option => tableState.value.activeMultiFilterIds?.includes(option.id)))?.flat(), ...activeChildren ]// The map method creates an array of arrays, and flat is used to combine these into a single array

		// setSearchValues() // update the searchValues object with the active filters (since some options may not be available yet when the route query is updated e.g. neighborhoods is fetched from the api and not available immediately on first load)
		// finally watch for activePresetOptions changes in the table component and emit to the parent component to be used to display the active filters
	}, { immediate: true, deep: true })

	watch(payload, (newValue, oldValue) => {
		if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
			pushRouteQueries()
		}
	}, { immediate: true, deep: true })

	/* TODO: Figure out how to handle the back or forward button click to update the searchValues object and the activeMultiFilterIds array
	watch(route, (to, from) => {
		console.log('route changed to:', to.fullPath)
		console.log('route changed from:', from.fullPath)
		if (to.fullPath !== from.fullPath && history.state && history.state.back) {
			console.log('back button clicked')
			setQueryStringValues()
			setSearchValues()
		}
	})
	*/

	onMounted(() => {
		setQueryStringValues() // set the initial values from the route query
	})

	// START: Table Cell Size Logic

	const smCellSize = 'text-xs px-6 py-1.5 font-normal'
	const mdCellSize = 'text-sm px-6 py-3 font-normal'
	const lgCellSize = 'text-sm h-[72px] px-6 py-4 font-normal'

	const smHeaderCellSize = 'text-xs px-6 py-1 font-normal'
	const mdHeaderCellSize = 'text-sm px-6 py-2 font-normal'
	const lgHeaderCellSize = 'text-sm px-6 py-3 font-normal'

	const smControlBarSize = 'py-3 px-3' // 'py-2 px-2'
	const mdControlBarSize = 'py-3 px-3'
	const lgControlBarSize = 'py-4 px-6'

	const smControlButtonSize = 'h-10' // 'h-8'
	const mdControlButtonSize = 'h-10'
	const lgControlButtonSize = 'h-12'

	const smDropdownSize = 'top-[40px]' // 'top-[32px]'
	const mdDropdownSize = 'top-[40px]'
	const lgDropdownSize = 'top-[48px]'

	const smPageInputSize = 'w-12 h-8'
	const mdPageInputSize = 'w-12 h-8'
	const lgPageInputSize = 'w-12 h-8'

	const smTextSize = 'text-xs'
	const mdTextSize = 'text-sm'
	const lgTextSize = 'text-lg'

	const cellSize = computed(() : CellSize => userSelectedCellSize.value !== '' ? userSelectedCellSize.value : props.tableCellSize)

	const cellSizeOptions: CellSize[] = [ 'sm', 'md', 'lg' ]

	const tableDataClasses = computed(() => {
		switch (cellSize.value) {
		case 'sm':
			return smCellSize
		case 'md':
			return mdCellSize
		case 'lg':
			return lgCellSize
		default:
			return mdCellSize
		}
	})

	const tableColumnClasses = computed(() => {
		switch (cellSize.value) {
		case 'sm':
			return smHeaderCellSize
		case 'md':
			return mdHeaderCellSize
		case 'lg':
			return lgHeaderCellSize
		default:
			return mdHeaderCellSize
		}
	})

	const tableTextClasses = computed(() => {
		switch (cellSize.value) {
		case 'sm':
			return smTextSize
		case 'md':
			return mdTextSize
		case 'lg':
			return lgTextSize
		default:
			return mdTextSize
		}
	})

	const controlButtonClasses = computed(() => {
		switch (cellSize.value) {
		case 'sm':
			return smControlButtonSize
		case 'md':
			return mdControlButtonSize
		case 'lg':
			return lgControlButtonSize
		default:
			return mdControlButtonSize
		}
	})

	const controlBarClasses = computed(() => {
		switch (cellSize.value) {
		case 'sm':
			return smControlBarSize
		case 'md':
			return mdControlBarSize
		case 'lg':
			return lgControlBarSize
		default:
			return mdControlBarSize
		}
	})

	const dropdownClasses = computed(() => {
		switch (cellSize.value) {
		case 'sm':
			return smDropdownSize
		case 'md':
			return mdDropdownSize
		case 'lg':
			return lgDropdownSize
		default:
			return mdDropdownSize
		}
	})

	const pageInputClasses = computed(() => {
		switch (cellSize.value) {
		case 'sm':
			return smPageInputSize
		case 'md':
			return mdPageInputSize
		case 'lg':
			return lgPageInputSize
		default:
			return mdPageInputSize
		}
	})

	const userSelectedCellSize: Ref<CellSize | ''> = useCookie(
		`${id}CellSize`,
		{ default: () => '' }
	)
	// END: Table Cell Size Logic

	return {
		payload,
		tableState,
		showTableControlBar,
		showPagination,
		showFilters,
		activeFilterLabel,
		activePresetOptions,
		presetFiltersWithAllOption,
		displayedColumns,
		showColumnReset,
		itemsPerPage,
		totalPages,
		tableDataClasses,
		tableColumnClasses,
		tableTextClasses,
		controlButtonClasses,
		controlBarClasses,
		dropdownClasses,
		pageInputClasses,
		cellSize,
		userSelectedCellSize,
		cellSizeOptions,
		handleSearchBlur,
		handleSort,
		handleFilterChange,
		formatCell,
		isLastRow,
		addOrRemoveColumn,
		addOrRemoveFilter,
		addOrRemovePresetOptions,
		addOrRemovePreset,
		showPresetOptionSelected,
		showMultiFilterReset,
		nextPage,
		previousPage,
		resetToFirstPage,
		showPresetActive,
		showPresetReset,
		clearPresetOptions,
		clearActivePresetOptions,
		clearActiveMultiFilters,
		resetColumnSelect,
		setQueryStringValues,
		pushRouteQueries,
		setPresetsLoading
	}
}

export type { TableState, TableProps, TableHelpers, TableMethods, TableApiParams, CellSize }
